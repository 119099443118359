.my_dropzone{
    border: 1px dashed #ccc;
    border-radius: 5px;
    padding: 15px;
    .inner_sec{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .__caption{
        flex: 1;
        margin:0 15px;
        p+p{
            color:#ccc;
        }
    }
    .active__drop{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
}