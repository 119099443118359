.testimonials .slick-slide {
    opacity: 0.3;
    transition: opacity 0.3s;
    padding-left: 1rem;
    padding-right: 1rem;

    @media only screen and (max-width: 600px) {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    &.slick-cloned {
        opacity: 0.3;
        transition: opacity 0.3s;
    }

    &.slick-current.slick-active {
        opacity: 1;
        transition: opacity 0.3s;
    }
}

.profileCards .slick-slide {
    padding-right: 0.8rem;

    @media only screen and (max-width: 600px) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }

    @media only screen and (max-width: 900px) {
        // padding-left: 0.8rem;
        padding-right: 0.5rem;

    }

}

.review-slider .slick-slide {
    padding-right: 0.8rem;

    @media only screen and (max-width: 600px) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }

    @media only screen and (max-width: 900px) {
        // padding-left: 0.8rem;
        padding-right: 0.5rem;

    }

}


.profileCards .slick-track {
    margin-left: 0 !important;
}

.slick-dots {
    width: auto;
    // left: 48%;
    // top: 325px
    bottom: -30px !important;
}

.slick-dots li button::before {
    font-size: 15px !important;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #880808 !important;
}