.nav-ul {
    list-style: none;
    gap: 20px
}

.nav-link {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 400;
    // font-family: "Poppin";
}

@media only screen and (max-width: 800px) {
    .nav-ul {
        list-style: none;
        gap: 5px;
       
    }
}

@media only screen and (max-width: 1200px) {
    .nav-link {
        font-size: 16px;
        gap: 10px
    }
}


.inner-wrapper {
    position: relative;
    display: inline-block;
}

.wrapper-16 {
    color: #FFF;
}

.wrapper-16:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: #FFF;
    transform: scaleY(0);
    transform-origin: bottom left;
    transition: transform 0.3s;
}

.wrapper-16:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: #FFF;
    transform: scaleY(0);
    transform-origin: top right;
    transition: transform 0.3s;
}

.wrapper-16 .hover-16 {
    padding: 10px;
}

.hover-16:hover {
    Color: #880808;

}

.wrapper-16 .hover-16:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #FFF;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s;
}

.wrapper-16 .hover-16:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: #FFF;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
}

.wrapper-16:hover:before,
.wrapper-16:hover:after {
    transform: scaleY(1);
}

.wrapper-16:hover .hover-16:before,
.wrapper-16:hover .hover-16:after {
    transform: scaleX(1);
}

.nav-active{
    color: #880808;
    transform: scaleX(1);
}