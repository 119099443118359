* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", 'Parisienne', cursive;
  scroll-behavior: "smooth";

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button[disabled] {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// body {
//     background-color: #FAFAFA !important;
// }

// .slick-initialized {
//     overflow: hidden;
// }



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(0, 0, 0);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff00 inset;
  transition: background-color 5000s ease-in-out 0s;
}