.permission_dialog{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: -2000;
    background-color: #ffffff;
    box-shadow: 0 0 10px #00000018;
    padding: 10px 10px 30px 10px;
    &__title{
        font-size: clamp(1.2rem,2vw,1.75rem);
        margin-bottom: .2em;
    }
    &__text{
        font-size: 0.75rem;
    }
    &.show{
        bottom: 0;
        z-index: 99;
    }
}