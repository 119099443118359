.splash_container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100vh;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green;
    animation: transit 2s infinite ease-in-out;

    img {
        animation: rotate 2s infinite ease-in-out;
    }
}

@keyframes rotate {
    to {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes transit {
    0% {
        background-color: rgb(204, 204, 204);
    }

    50% {
        background-color: rgba(204, 204, 204, 0.6);
    }

    100% {
        background-color: rgb(204, 204, 204);
    }
}



















.container {
    left: 50%;
    margin: auto -50px;
    position: absolute;
    top: 50%;
}

.swing div {
    border-radius: 50%;
    float: left;
    height: 1em;
    width: 1em;
}

.swing div:nth-of-type(1) {
    background: #880808;
}

.swing div:nth-of-type(2) {
    background: #770707;
}

.swing div:nth-of-type(3) {
    background: #660606;
}

.swing div:nth-of-type(4) {
    background: #550505;
}

.swing div:nth-of-type(5) {
    background: #440404;
}

.swing div:nth-of-type(6) {
    background: #330303;
}

.swing div:nth-of-type(7) {
    background: #220202;
}

.swing div:nth-of-type(8) {
    background: #110101;
}

.swing div:nth-of-type(9) {
    background: #000000;
}

.shadow {
    clear: left;
    padding-top: 1.5em;
}

.shadow div {
    -webkit-filter: blur(1px);
    filter: blur(1px);
    float: left;
    width: 1em;
    height: .25em;
    border-radius: 50%;
    background: #e3dbd2;
}



@-webkit-keyframes ball-l53 {

    0%,
    50% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0);
    }

    100% {
        -webkit-transform: rotate(50deg) translateX(-2.5em);
        transform: rotate(50deg) translateX(-2.5em);
    }
}

@keyframes ball-l53 {

    0%,
    50% {
        -webkit-transform: rotate(0) translate(0);
        transform: rotate(0) translateX(0);
    }

    100% {
        -webkit-transform: rotate(50deg) translateX(-2.5em);
        transform: rotate(50deg) translateX(-2.5em);
    }
}

@-webkit-keyframes ball-r53 {
    0% {
        -webkit-transform: rotate(-50deg) translateX(2.5em);
        transform: rotate(-50deg) translateX(2.5em);
    }

    50%,
    100% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0);
    }
}

@keyframes ball-r53 {
    0% {
        -webkit-transform: rotate(-50deg) translateX(2.5em);
        transform: rotate(-50deg) translateX(2.5em);
    }

    50%,
    100% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0)
    }
}

@-webkit-keyframes shadow-l-n53 {

    0%,
    50% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: .125;
        -webkit-transform: translateX(-1.57em);
        transform: translateX(-1.75em);
    }
}

@keyframes shadow-l-n53 {

    0%,
    50% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: .125;
        -webkit-transform: translateX(-1.75);
        transform: translateX(-1.75em);
    }
}

@-webkit-keyframes shadow-r-n53 {
    0% {
        opacity: .125;
        -webkit-transform: translateX(1.75em);
        transform: translateX(1.75em);
    }

    50%,
    100% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes shadow-r-n53 {
    0% {
        opacity: .125;
        -webkit-transform: translateX(1.75em);
        transform: translateX(1.75em);
    }

    50%,
    100% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.swing-l {
    -webkit-animation: ball-l53 .425s ease-in-out infinite alternate;
    animation: ball-l53 .425s ease-in-out infinite alternate;
}

.swing-r {
    -webkit-animation: ball-r53 .425s ease-in-out infinite alternate;
    animation: ball-r53 .425s ease-in-out infinite alternate;
}

.shadow-l {
    -webkit-animation: shadow-l-n53 .425s ease-in-out infinite alternate;
    animation: shadow-l-n53 .425s ease-in-out infinite alternate;
}

.shadow-r {
    -webkit-animation: shadow-r-n53 .425s ease-in-out infinite alternate;
    animation: shadow-r-n53 .425s ease-in-out infinite alternate;
}